import React, { useEffect, useState } from 'react'
import StackGrid from "react-stack-grid";

export const VideoGallery = (props) => {
    const { images, onLoaded } = props
    const [imgLoaded, setImgLoaded] = useState([...Array(images.length).keys()].map(a => false));
    const [notLoading, setNotLoading] = useState(false);
    useEffect(() => {
      const load = imgLoaded.reduce((prev, curr) => {
        return prev && curr
      }, true)
      console.log('loading', imgLoaded, load);
      setNotLoading(load)
      onLoaded(load)
    }, [imgLoaded, onLoaded])
    const changeArray = (index) => {
      const tm = [...imgLoaded]
      tm[index] = true
      setImgLoaded([...tm])
    }
    // const images = [
    //     {
    //       original: 'https://picsum.photos/id/1001/1000/600',
    //       thumbnail: 'https://picsum.photos/id/1001/250/150',
    //       embedUrl: 'https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0',
    //     // renderItem: this._renderVideo.bind(this)
    //     },
    //     {
    //       original: 'https://picsum.photos/1000/600',
    //       thumbnail: 'https://picsum.photos/250/150'
    //     },
    //     {
    //       original: 'https://picsum.photos/1000/600',
    //       thumbnail: 'https://picsum.photos/250/150'
    //     }
    //   ]
    return [(
        // <div style={{'max-width': 800}}>
        //     <ImageGallery items={images} />
        // </div>
        <StackGrid
        columnWidth={300}
        gutterWidth={20}
        style={notLoading ? {}: {display: 'none'}}
      >
        {images.map((image, index) => {
          return (
            <div key={"key"+index}>
              <img style={imgLoaded[index] ? {}: {display: 'none'}}  width={"100%"} onLoad={() => changeArray(index)} height="auto" src={image.original} alt={image.original} />
            </div>
          )
        })}
      </StackGrid>
    )
  ]
}
