import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify, {Storage} from "aws-amplify"
import reportWebVitals from './reportWebVitals';
import awsvideoconfig from "./aws-video-exports"
import { BrowserRouter } from "react-router-dom";
import awsmobile from "./aws-exports"
Amplify.configure(awsmobile);
Storage.configure({
    AWSS3: {
      bucket: awsvideoconfig.awsInputVideo,
      region: awsmobile.aws_project_region,
      customPrefix: {
      public: '',
      }
    },
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
