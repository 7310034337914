// import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import "./App.css";
import { mascaras, header_fotos } from "./mascaras";
import { PhotoMap } from "./PhotoMap";
import { useLocation } from "react-router-dom";
import { VideoGallery } from "./VideoGallery";
import decoracionHeader from "./assets/decoracion_completa.png";
import exploracion1 from "./assets/exploracion1.png";
import exploracion2 from "./assets/exploracion2.png";
import exploracion3 from "./assets/exploracion3.jpg";
import exploracion4 from "./assets/exploracion4.gif";
import { ButtonOutline } from "./ButtonOutline";
import { Route, Routes } from "react-router-dom";
import Modal from "react-modal/lib/components/Modal";
import { InfinitySpin } from "react-loader-spinner";
// import VideoJS from './VideoJS';
const customStyles = {
  content: {
    fontFamily: "'Economica', sans-serif",
    fontSize: 25,
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function App() {
  console.log(mascaras[0]);
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  // function openModal() {
  //   setIsOpen(true);
  // }
  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    console.log("location", location);
    if (location.pathname === "/") setIsOpen(true);
  }, [location]);
  return [
    <div className="App">
      {/* <div id="header">
        
      </div> */}
      <div id="sidebar">
        {/* <div className="menu-img" id="senora">
          <img src="fotos/archivo897.png" alt="" style={{"WebkitMaskImage": "url(http://localhost:3000/mascara56.png)", "WebkitMaskRepeat": "no-repeat", "WebkitMaskPosition": "244px -151px", "WebkitMaskSize": "1180px"}}></img>
        </div>
        <div className="menu-img" id="moto">
          <img src="fotos/archivo429.png" alt="" style={{"WebkitMaskImage": "url(http://localhost:3000/mascara127.png)", "WebkitMaskRepeat": "no-repeat", "WebkitMaskPosition": "600px -151px" }}></img>
        </div>
        <div className="menu-img" id="chica">
          <img src="fotos/archivo501.png" alt="" style={{"WebkitMaskImage": "url(http://localhost:3000/mascara30.png)", "WebkitMaskRepeat": "no-repeat", "WebkitMaskPosition": "600px -183px", "WebkitMaskSize": "1430px"}}></img>
        </div>
        <div className="menu-img" id="ninos">
          <img  src="fotos/archivo223.png" alt="" style={{"WebkitMaskImage": "url(http://localhost:3000/mascara121.png)", "WebkitMaskRepeat": "no-repeat", "WebkitMaskPosition": "250px -151px", "WebkitMaskSize": "1430px"}}></img>
        </div>
          <ButtonOutline backgroundColor="white">Geovisual</ButtonOutline> */}
        <img
          alt="Logo"
          width="100%"
          src={process.env.PUBLIC_URL + "/logopc.png"}
        />
        <ButtonOutline link="/about" className="btn-principal">
          Proceso
        </ButtonOutline>
        <ButtonOutline link="/" className="btn-principal">
          Mapa
        </ButtonOutline>
        <ButtonOutline link="/creditos" className="btn-principal">
          Anexos
        </ButtonOutline>
        <div className="header-banner">
          Este es el producto final de Geovisual un proyecto de
          investigación/creación, que propone una reflexión sobre el territorio
          en la creación audiovisual.
          <br />
          <br />
          En este mapa interactivo el público encuentra una representación de un
          territorio imaginado a partir de lo audiovisual. Esta aplicación toma
          los croquis de diferentes barrios de Manrique, cómo máscara de
          fragmentos de video de los cortometrajes Paseadores de perros (Pánico,
          2019) y la Casa de todos, (Pánico, 2019) trabajos realizados en el
          barrio Manrique en Medellín Colombia, en el marco del proceso
          Manrique, cámaras acción en el año 2019. Creando mapas a partir de los
          videos y los croquis.
          <br />
          {/* Puedes organizar el mapa a tu manera moviendo los croquis en la
          pantalla. Al dar click en el número que aparece debajo del mapa puedes
          escoger la cantidad de barrios del nuevo mapa generado
          automáticamente. El botón de añadir te permite añadir nuevos barrios,
          debes escoger el nombre del barrio y el concepto que más relaciones
          con este.
          <br />
          Disfruta creando tu propio mapa. */}
        </div>
      </div>
      <div id="content">
        <Routes>
          <Route path="/" element={<Videos />} />
          <Route path="about" element={<Gallery />} />
          <Route path="creditos" element={<Creditos />} />
        </Routes>
        {/* <section>
        <VideoPlayer 
        autoplay
        loop
        sources={[{
          src: 'https://geovisualvideos-fvideo-output-0urgb7nh.s3.us-west-2.amazonaws.com/PDP4/PDP4_1500.m3u8',
          type: 'application/x-mpegURL'
        }]} />
        </section> */}
      </div>
      {/* <div id="footer">
        <img
          alt="Logo"
          width="40%"
          src={process.env.PUBLIC_URL + "/footer.png"}
        />
      </div> */}
    </div>,
    <Modal
      isOpen={modalIsOpen}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Añadir nuevo video"
    >
      <div id="tutorial">
        <h1 style={{ textAlign: "center", color: "#FFCC66" }}>Tutorial</h1>
        <p>
          Este es un mapa interactivo que representa un “espacio imaginado” a
          partir de lo audiovisual. Esta aplicación toma los croquis de
          diferentes barrios de Manrique, cómo máscara de fragmentos de video de
          los cortometrajes Paseadores de perros (Pánico, 2019) y la Casa de
          todos, (Pánico, 2019) y así crea mapas a partir de videos.
        </p>
        <ul>
          <li>
            Puedes organizar el mapa a tu manera moviendo los croquis en la
            pantalla.
          </li>
          <li>
            Dando click en el número que aparece debajo del mapa puedes escoger
            la cantidad de barrios del nuevo mapa generado automáticamente.
          </li>
          <li>
            Con el botón Añadir puedes agregar nuevos barrios, escogiendo el
            nombre del barrio y el concepto que más relaciones con este.{" "}
          </li>
        </ul>
        <p>Disfruta creando tu propio mapa.</p>
      </div>
      <div className="btn-container">
        <button onClick={() => setIsOpen(false)} className="btn-principal">
          Aceptar
        </button>
      </div>
    </Modal>,
  ];
}

function Videos() {
  return (
    <section id="random-map">
      <div className="randommap-container">
        <PhotoMap fotos={header_fotos} mascaras={mascaras} />
      </div>
    </section>
  );
}

function Gallery() {
  const [galeria1Loaded, setGaleria1Loaded] = useState(false);
  const [galeria2Loaded, setGaleria2Loaded] = useState(false);
  return [
    <section className="video-gallery">
      <img className="deco" src={decoracionHeader} alt="Decoración" />
      <div className="title-outlined">
        <h1>Exploraciones visuales</h1>
      </div>
      <img width={960} src={exploracion1} alt="Decoración" /><br />
      <img width={960} src={exploracion2} alt="Decoración" /><br />
      <img width={960} src={exploracion3} alt="Decoración" /><br />
      <img width={960} src={exploracion4} alt="Decoración" /><br />
      <div style={galeria2Loaded ? {} : { display: "none" }}>
        <img className="deco" src={decoracionHeader} alt="Decoración" />
        <div className="title-outlined">
          <h1>Resultados del taller</h1>
        </div>
        <VideoGallery
          onLoaded={(es) => setGaleria2Loaded(es)}
          images={[...Array(30).keys()].map((n) => {
            return {
              original: process.env.PUBLIC_URL + "/fotos/Collage" + n + ".jpg",
            };
          })}
        />
      </div>
    </section>,
    <section className="video-gallery">
      <div style={galeria1Loaded ? {} : { display: "none" }}>
        <img className="deco" src={decoracionHeader} alt="Decoración" />
        <div className="title-outlined">
          <h1>Taller de collage</h1>
        </div>
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Z31RiGLAGcs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <br />
        <br />
        <VideoGallery
          onLoaded={(es) => setGaleria1Loaded(es)}
          images={[...Array(34).keys()].map((n) => {
            return {
              original: process.env.PUBLIC_URL + "/memorias/im" + n + ".jpg",
            };
          })}
        />
      </div>
      {galeria2Loaded && galeria1Loaded ? null : <InfinitySpin color="gray" />}
    </section>,
  ];
}

function Creditos() {
  return (
    <section id="creditos">
      <div className="banner-anexos">
        <p>
          Aquí encontrarás el documento de investigación, las fichas de análisis
          y el sistema categorial. El documento constituye el informe final de
          la investigación Geovisual: Manrique este es un proyecto de
          investigación-creación presentado para optar al título de Comunicadora
          Audiovisual y Multimedial de la Universidad de Antioquia.
        </p>

        <p>
          Ese producto interactivo propone una reflexión sobre el territorio en
          el cortometraje y sobre Manrique como barrio imaginado de manera
          audiovisual. En el mapa interactivo el público encuentra una
          representación de un territorio imaginado a partir de lo audiovisual.{" "}
        </p>

        <p>
          Las fichas de análisis y el sistema categorial, son parte de los
          productos que se generaron con la investigación y permitieron el
          desarrollo de la aplicación.{" "}
        </p>
        <div className="texto-galeria">
          <a
            rel="noreferrer"
            href={process.env.PUBLIC_URL + "/informe_final.pdf"}
            target="_blank"
          >
            Descargar PDF
          </a>
        </div>
      </div>
      <div className="creditos-header">
        <img
          width={250}
          src={process.env.PUBLIC_URL + "/Escudo-UdeA.png"}
          alt="Escudo UdeA"
        />
        <div>
          <h1>
            Proyecto apoyado por el Comité para el Desarrollo de la
            investigación de la Universidad de Antioquia
          </h1>
          <h2>Colectivo Audiovisual Pánico</h2>
          <h2>
            Semillero de investigación Comunicación Ambiental y Narrativas
            Territoriales (CANATE)
          </h2>
          <h2>Semillero de Investigación Imagen -Algoritmo</h2>
        </div>
      </div>
      <div className="creditos-content">
        <div>
          <h2>
            <span className="creditos-bold">Realizador:</span> María Camila
            García Parada
          </h2>
          <h2>
            <span className="creditos-bold">Asesor principal:</span> Ricardo
            Cedeño Montaña
          </h2>
          <h2>
            <span className="creditos-bold">Asesores laboratoristas:</span>Luis
            Carlos Ealo y Robinson Mejía{" "}
          </h2>
          <h2>
            <span className="creditos-bold">Asesor ad-honorem:</span> Carlos
            Augusto Giraldo
          </h2>
          <h2>
            <span className="creditos-bold">Tutores:</span> Nicolás Mejía, Ana
            Victoria Ochoa, Diego Hurtado Gañan{" "}
          </h2>
        </div>
        <div>
          <h2>
            <span className="creditos-bold">Diseñador:</span> Alejandro Pérez{" "}
          </h2>
          <h2>
            <span className="creditos-bold">Programador:</span> Alejandro
            Tabares
          </h2>
          <h2>
            <span className="creditos-bold">Asistente de análisis:</span> Raúl
            Álvarez{" "}
          </h2>
          <h2>
            <span className="creditos-bold">Agradecimientos:</span>Fabián Andrés
            Correa, Juan Manuel Espinosa, Esteban Estrada Gallego, Sigifredo
            Escobar, José Joaquín García, José Manuel García, Mateo Alejandro
            García, Lina González, Diego Hurtado, Irati Dojura Landa Yagari,
            Duván Londoño, Jeannette Parada, Javier Palacios, Oswaldo Osorio,
            Alejandro Pinto, Jacobo Ríos y Stiven Quinchia.
          </h2>
        </div>
      </div>
    </section>
  );
}

export default App;
