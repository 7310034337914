import React from 'react';
import Draggable from 'react-draggable';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class VideoPlayer extends React.Component {
  state = {
    top: 100,
    left: 100,
    // srco: ''
  }
  componentDidMount() {
    this.player = videojs(this.videoNode, {...this.props});
    this.player.ready(() => {
      console.log('ready');
      this.generatePosition()
    })
  //   if (this.props.onMounted) {
  //     this.props.onMounted({
  //         genPosition: () => this.generatePosition()
  //   });
  // }
    
  }
  componentDidUpdate() {
    // this.setState({srco: this.props.srco})
    // this.generatePosition().
  }
  generatePosition() {
    let box= document.querySelector('.photomap-container');
    // console.log(box.clientWidth, box.clientHeight)
    let videow = 640
    let videoh = 360
    let top = Math.random() * (box.clientHeight- videoh/2) - this.dragged.state.y
    let left = Math.random() * (box.clientWidth - videow/2) - this.dragged.state.x
    // console.log(this.dragged.state.x, this.dragged.state.y);
    this.setState({top, left})
  }
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      // <div className="clipped"  >
      <Draggable ref={(node) => { this.dragged = node; }} bounds=".photomap-container">
        <div style={{top: this.state.top + "px", left: this.state.left + "px", position: "absolute"}}>
          <div data-vjs-player >
            <video muted style={{...this.props.style, width: 320, height:180}} ref={(node) => { this.videoNode = node; }}>
              <source src={this.props.srco} type="video/mp4"></source>
            </video>
          </div>
        </div>
      </Draggable>
      // </div>
    );
  }
}
